.s-heading {
  .home & {
    height:1px;
    overflow:hidden;
    margin-bottom:0;
  }
  @extend .row;
  font-weight:$regular;
  margin-bottom:$size-e;
  &__title {
    margin-bottom:0;
  }
  &__arrow {
    @extend .hidden-xs;
    @extend .col-sm-1;
    background-image:url("../images/page-title-arrow.svg");
    background-repeat:no-repeat;
    background-position:left $gutter top;
    height:1.1em;
    display:inline-block;
    vertical-align:top;
  }
  &__text {
    @extend .col-xs-12;
    @extend .col-sm-11;
    @extend .all-caps-tight;
    color:$blue;
    @include for(mobile-only) {
      font-size:$size-c;
    }
  }
}
