.s-search {
  margin-top:3em;
  @include for(tablet) {
    font-size:$size-e;
  }
  font-weight:$light;
  .s-form {
    &__group {
      &:first-child {
        @extend .col-xs-8;
        @extend .col-sm-8;
      }
      &:last-child {
        @extend .col-xs-4;
        @extend .col-sm-4;
      }
    }

    &__input {
      background-color:$white;
      @include for(tablet) {
        background-image:url("../images/search-icon.svg");
        background-repeat:no-repeat;
        background-position:center left 10px;
        background-size:1.5em auto;
        padding-left:2.5em;
      }
    }

    &__button {
      @extend .s-button;
      @extend .s-button--blue;
    }
  }
  &--panel {
    background-color:$white;
    border-left:2px solid $yellow;
    padding:1em;
    @include for(tablet) {
      padding:1.5em 3em;
    }
    .s-search {
      &__text {
        @extend .col-xs-12;
        @extend .col-md-6;
        margin:0 0 20px 0;
        @include for(desktop) {
          margin:10px 0;
        }
        font-size:$size-d;
        font-weight:$normal;
      }
    }
    .s-form {
      @extend .col-xs-12;
      @extend .col-md-6;
      &__input {
        background-color:$grey-lighter;
        border-color:$grey-lighter;
      }
    }
  }

}
