.s-button {
  transition:all .15s ease-in-out;
  &--yellow {
    background-color:$yellow;
    border:1px solid $yellow;
    color:$black;
    &:hover, &:active, &:focus {
      background-color:$blue;
      border-color:$blue;
      color:$white;
    }
  }
  &--blue {
    background-color:$blue;
    border:1px solid $blue;
    color:$white;
    &:hover, &:active, &:focus {
      background-color:$yellow;
      border-color:$yellow;
      color:$black;
    }
  }
}
