.s-team {
  &__list {
    @extend .list-unstyled;
    @extend .row;
  }
  &__member {
    @extend .col-xs-12;
    @extend .col-sm-6;
    @extend .col-md-4;
    margin-bottom:20px;
  }
  &__image {
    @extend .col-xs-5;
    img {
      width:100%;
      height:auto;
    }
    .image-filter {
      width:100%;
      &:before {
        content:"";
        position:absolute;
        bottom:-10px;
        width:110%;
        left:-10px;
        background-color:$grey;
        display:inline-block;
        height:25px;
        z-index:1;
        transform:rotate(4deg);
      }
    }
  }
  &__content {
    @extend .col-xs-7;
    min-height:163px;
  }
  &__name {
    color:$blue;
    font-size:$size-e;
    margin:5px 0 5px 0;
  }
  &__job {
    h2 {
      font-size:$size-c;
      @extend .caps-black-heading;
    }
  }
}
