.s-footer {
  &__rah {
    font-size:$size-d;
    @include for(mobile-only) {
      border-top:1px solid $grey-darkest;
      border-bottom:1px solid $grey-darkest;
      padding-top:10px;
      padding-bottom:10px;
      font-size:$size-c;
    }
    @include for(tablet) {
      &:before {
        content:'';
        background:$grey-darkest;
        height:1px;
        width:100%;
        display:block;
        position:absolute;
        top:50%;
        z-index:1;
      }
    }
    @include for(desktop) {
      font-size:$size-d;
    }
    position:relative;
    text-align:center;
    font-style:italic;
    span {
      background:$grey;
      padding:0 0.5em;
      display:inline-block;
      z-index:2;
      position:relative;
    }
    em {
      @extend .text-hide;
      display:inline-block;
      width:100px;
      height:53px;
      margin-top:10px;
      @include for(tablet) {
        margin-top:-15px;
        width:130px;
        height:68px;
      }
      background-image:url("../images/rah-logo.svg");
      background-repeat:no-repeat;
      background-position:center center;
      background-size:contain;
      vertical-align:middle;
    }
  }

  &__help {
    margin-top:50px;
    margin-bottom:0;
    @include for(tablet) {
      margin-top:100px;
      font-size:$size-e;
    }
    a {
      @include for(tablet) {
        font-size:$size-e;
      }
      color:$blue;
      display:block;
    }
  }

  &__image {
    float:left;
    padding-right:5px;
    img {
      width:148px;
      height:89px;
      @include for(tablet) {
        width:300px;
        height:180px;
      }
    }
  }

  &__navigation {
    background-color:$blue;
    padding:1em 0 0;
    .widget {
      @extend .col-xs-12;
      @extend .col-sm-6;
      @extend .col-md-3;
      ul {
        @extend .list-unstyled;
      }
      a {
        color:$white;
        margin-bottom:0.2em;
        display:inline-block;
        font-weight:$light;
      }
      > ul {
        margin-bottom:1em;
        > li {
          margin-bottom:1em;
          > a {
            @extend .all-caps;
            font-weight:$regular;
            margin-bottom:0.5em;
          }
        }
      }
    }
  }

  &__content {
    background-color:$white;
    color:$blue;
    padding-bottom:2em;
  }

  &__follow {
    @extend .col-xs-12;
    @extend .col-sm-4;
    @extend .col-md-3;
    h3 {
      @extend .all-caps;
      font-weight:$regular;
    }
  }

  &__email {
    @extend .col-xs-12;
    @extend .col-sm-8;
    @extend .col-md-9;
    h3 {
      @extend .all-caps;
      font-weight:$regular;
    }
    .mc4wp-form {
      @extend .row;
      .mc4wp-response {
        @extend .col-xs-12;
        p {
          margin-top:0;
        }
      }
    }
    .s-form {
      &__group {
        @extend .col-xs-12;
        @extend .col-sm-6;
        @extend .col-md-3;
        margin-bottom:1em;
      }
    }
  }

  &__copyright {
    @extend .col-xs-12;
    @extend .col-sm-6;
    font-size:$size-c;
    margin-top:1em;
    a {
      font-weight:$bold;
    }
  }

  &__logo {
    @extend .col-xs-12;
    @extend .col-sm-6;
    margin-top:1em;
  }

  &__brand {
    background-image:url("../images/sfl-logo-web-blue.svg");
    background-repeat:no-repeat;
    background-size:contain;
    width:50px;
    height:25px;
    display:inline-block;
    @include for(tablet) {
      float:right;
    }
  }
}

.s-social {
  @extend .list-inline;
  float:left;
  li {
    float:left;
  }
  a {
    background-repeat:no-repeat;
    background-size:contain;
    background-position:center center;
    @extend .text-hide;
    width:50px;
    height:30px;
    float:left;
  }
  &__facebook {
    a {
      background-image:url("../images/fb-logo.svg");
    }
  }
  &__twitter {
    a {
      background-image:url("../images/twitter-logo.svg");
    }
  }
  &__youtube {
    a {
      background-image:url("../images/youtube-logo.svg");
    }
  }
}
