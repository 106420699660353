.s-forward {
  min-height:30px;
}

.s-paths {
  @extend .row;
  margin-top:2em;
  padding-top:2em;
  margin-bottom:50px;
  border-top:solid 1px rgba(255,255,255, 0.5);

  .home & {
    .s-paths__section {
      @extend .col-sm-12;
      @extend .col-md-7;
      &--content {
        h2 {
          font-size:$size-f;
          @include for(tablet) {
            font-size:$size-g;
          }
          background-image:url("../images/sfl-logo-web-blue.svg");
          background-repeat:no-repeat;
          background-size:1.9em auto;
          background-position:center left;
          padding-left:2.2em;
          margin-bottom:0.5em;
          margin-top:0;
        }
        strong {
          font-weight:$normal;
          font-size:$size-e;
          a {
            &:after {
              content: "";
              background-image:url("../images/right-arrow.svg");
              background-repeat:no-repeat;
              background-size:contain;
              display:inline-block;
              margin-left:10px;
              width:40px;
              height:30px;
              vertical-align:bottom;
            }
          }
        }
      }
      &.s-paths__section--news {
        @extend .col-md-4;
        float:right;
        margin-top:2em;
        @include for(desktop) {
          margin-top:6em;
        }
      }
      &.s-paths__section--page {
        .s-paths__info {
          margin-left:-4em;
          background:$white;
          padding:1em 2em;
          @include for (mobile-only){
            margin-left:0;
            padding-left: 0;
          }
        }
      }
    }

  }

  &__section {
    @extend .col-xs-12;
    @extend .col-sm-6;

    &--courses {
      .s-paths__item {
        margin-bottom:10px;
      }
      .s-paths__link {
        font-size:$size-e;
      }
    }

    &--page,
    &--article {
      .s-paths {
        &__link {
          @extend .row;
          @include for(tablet) {
            @extend .vertical-align;
          }
          text-decoration:none;
          &:hover {
            .s-paths__content {
              text-decoration:none;
              span {
                text-decoration:underline;
              }
            }
          }
        }

        &__heading {
          @extend .invisible;
        }

        &__image {
          @extend .col-xs-4;
          @extend .col-sm-6;
        }

        &__info {
          @extend .col-xs-8;
          @extend .col-sm-6;
          font-size:$size-c;
          @include for(tablet) {
            font-size:$size-d;
            margin-top:-30px;
          }
        }

        &__content {
          color:$black;
          font-size:$size-e;
          position:relative;
          span {
            color:$blue;
          }
          &:after {
            content: "";
            background-image:url("../images/right-arrow.svg");
            background-repeat:no-repeat;
            background-size:contain;
            display:block;
            width:30px;
            height:20px;
            margin-top:5px;
            @include for(tablet) {
              width:40px;
              height:30px;
            }
          }
        }
      }
    }

    &--article {
      .s-paths {
        &__link {
          &:hover {
            .s-paths__title {
              text-decoration:none;
            }
            .s-paths__content {
              text-decoration:underline;
            }
          }
        }
        &__info {
          @include for(tablet) {
            margin-left:-12%;
          }
        }
        &__title {
          @extend .caps-black-heading;
          color:$black;
          font-size:$size-d;
          margin-bottom:0;

        }
        &__content {
          color:$blue;
          margin-top:0;
        }
      }
    }

    &--news {
      .s-paths {
        &__title {
          margin-bottom:0;
        }
        &__content {
          font-style:italic;
        }
        &__info {
          @extend .col-xs-12;
        }
      }
    }
  }

  &__heading {
    @extend .caps-black-heading;
  }

  &__list {
    @extend .list-unstyled;
    margin-bottom:$gutter;
    @include for(tablet) {
      padding-right:$size-g;
      .s-sidebar & {
        padding-right:0;
      }
    }
  }

  &__more {
    font-size:$size-e;

    a {
      &:after {
        content: "";
        background-image:url("../images/right-arrow.svg");
        background-repeat:no-repeat;
        background-size:contain;
        display:inline-block;
        width:40px;
        height:30px;
        margin-left:10px;
        vertical-align:text-top;
      }
    }
  }

  &__item {
    margin-bottom:$gutter;
  }

  &__image {
    @extend .col-xs-3;
    .image-filter,
    img {
      width:100%;
      height:auto;
    }
  }

  &__link {
    &:hover {
      .s-paths__title {
        text-decoration:underline;
      }
    }
  }

  &__info {
    @extend .col-xs-9;
  }

  &__title {
    color:$blue;
    font-size:$size-e;
    margin:$size-a 0;
  }

  &__content {
    font-size:$size-d;
    color:$black;
    margin:$size-a 0;
  }

}
