.s-sidebar {
  &__item {
    margin-bottom:3em;

    &--author {
      img {
        width:100%;
        height:auto;
      }
      p {
        text-align:center;
        span {
          font-style:italic;
        }
        a {
          margin-bottom:$size-b;
          &:after {
            content:", ";
          }
          &:last-of-type {
            &:after {
              content:"";
            }
          }
        }
      }
    }

    &--categories {
      h4 {
        @extend .caps-black-heading;
        margin-top:$size-e;
        margin-bottom:$size-e;
      }
      ul {
        @extend .list-unstyled;
        li {
          margin-bottom:$size-b;
        }
      }
    }

    &--quote {
      color:$blue;
      blockquote {
        position:relative;
        margin:0;
        margin-bottom:$size-c;
        color:$black;
        font-style:italic;
        @include for(tablet) {
          font-size:$size-e;
        }
        &:before {
          content:"";
          background-image:url("../images/quote-marks.svg");
          background-repeat:no-repeat;
          background-size:contain;
          @include for(tablet) {
            position:absolute;
            left:-50px;
            top:-10px;
          }
          display:block;
          margin-bottom:10px;
          width:40px;
          height:40px;
        }
      }
    }

    &--content {
      text-align:center;
      @extend .clearfix;
      img {
        width:70%;
        @include for(tablet) {
          width:50%;
          float:left;
        }
        @include for(desktop) {
          width:100%;
          float:none;
        }
        height:auto;
        border-bottom:3px solid $blue;
      }
      h3 {
        @include for(tablet-only) {
          margin-top:60px;
        }
        font-size:$size-e;
        margin-bottom:0;
        text-align:center;
      }
      h4 {
        font-size:$size-f;
        color:$blue;
        margin-top:0;
        margin-bottom:0;
        text-align:center;
      }
      p {
        a {
          font-size:$size-e;
          &:after {
            content:"";
            background-image:url("../images/right-arrow.svg");
            background-repeat:no-repeat;
            background-size:contain;
            display:inline-block;
            width:40px;
            height:30px;
            margin-left:10px;
            vertical-align:text-top;
          }
        }
      }
    }
  }
}
