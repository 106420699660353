.s-categories {
  @extend .list-unstyled;
  &__link {
    display:block;
    position:relative;
    margin-bottom:30px;
    @include for(desktop) {
      height:360px;
    }
  }
  &__title {
    margin-bottom:10px;
    display:block;
    @include for(desktop) {
      margin-bottom:0px;
      position:absolute;
      bottom:0;
      left:15px;
      transform:rotate(270deg);
      transform-origin:left bottom 0;      
    }
    @extend .caps-black-heading;
  }
  &__item {
    float:left;
    width:100%;
    clear:both;
    @include for (desktop){
      margin-bottom: 30px;
    }
  }
  &__content {
    @include for(desktop) {
      margin-left:20px;
    }
    ul {
      @extend .list-inline;
      margin-left:0;
      li {
        float:left;
        @include for(desktop) {
          height:360px;
          width:160px;
        }
        position:relative;
        padding:0;
        margin:0 5px;
        display:none;
        @include for(desktop) {
          display:block;
        }
        @include for(large-desktop) {
          width:244px;
        }
        .image-filter {
          position:initial;
          position:absolute;
          bottom:0;
        }
        img {
          width:100%;
          height:auto;
        }
        &:first-child {
          width:100%;
          display:block;
          @include for(tablet) {
            width:480px;
          }
          @include for(desktop) {
            width:480px;
          }
          float:left;
          margin-left:0;
          .image-filter {
            position:relative;
          }
        }
        &:last-child {
          display:block;
          width:100%;
          margin-top:10px;
          line-height: 1.15;
          @include for(desktop) {
            width:320px;
            position:absolute;
            left:520px;
            bottom:100px;
          }
          @include for(large-desktop) {
            width:488px;
            bottom:125px;
          }
          @include for (mobile-only) {
            height: auto;
            margin-bottom: $size-d;
          }
          height:180px;
          color:$black;
          font-size:$size-e;
          strong {
            color:$blue;
            @include for(tablet) {
              font-size:$size-e;
            }
            font-weight:normal;
            display:block;
            &:after {
              content: "";
              background-image:url("../images/right-arrow.svg");
              background-repeat:no-repeat;
              background-size:contain;
              display:block;
              margin-top:5px;
              width:40px;
              height:30px;
            }
          }
        }
      }
    }
  }
}