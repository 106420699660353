* {
  @include box-sizing(border-box);
}

*:before,
*:after {
  @include box-sizing(border-box);
}

// * Text selection * //
::-moz-selection { /* Code for Firefox */
  color: $black;
  background: $yellow;
}

::selection {
  color: $black;
  background: $yellow;
}

html {
  font-size:100%;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
}

body {
  background-color:$grey;
  color:$grey-darkest;
  font-family:'Chivo', sans-serif;
  line-height:1.3125;
}

a {
  text-decoration:none;
  color:$blue;
  &:hover,
  &:focus {
    text-decoration:underline;
  }
}

h1, h2, h3, h4, h5, h6 {
  font-weight:normal;
  line-height:1.15;
}

figure {
  margin: 0;
}

img {
  vertical-align: middle;
}

[role="button"] {
  cursor: pointer;
}